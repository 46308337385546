// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { applyMiddleware, compose, createStore } from 'redux'
import monitorReducersEnhancer from './../enhancers/monitorReducer'
import loggerMiddleware from './../middleware/logger'
import rootReducer from './../reducers'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'

export const configureAppStore = (preloadedState) => {
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = compose(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./../reducers', () => store.replaceReducer(rootReducer))
  }
  return store
}

export default configureAppStore