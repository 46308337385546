import axios from 'axios';
import FloraActions from '../../reducers/flora/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url = process.env.REST_URL + 'db/flora'

export default class FloraMiddleware {
  static getList(){
    return (dispatch) => {
      dispatch(FloraActions.process());
      FloraMiddleware.showlist(dispatch);
    };
  }
  static getData(params){
    return (dispatch) => {
      dispatch(FloraActions.process());
      FloraMiddleware.showData(dispatch,params);
    };
  }
  static showData(dispatch,params) {
    axios({
      method: 'GET',
      url: '/db/flora/'+params+'.json',
      responseType: 'json',
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(FloraActions.view(response.data));
        }
      })
      .catch((error) => {
        dispatch(FloraActions.error(null));
        FloraMiddleware.alertHendler(dispatch, error)
      });
  }
  static showlist(dispatch) {
    axios({
      method: 'GET',
      url: '/db/MenuFlora.json',
      responseType: 'json',
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(FloraActions.list(response.data));
        }
      })
      .catch((error) => {
        FloraMiddleware.alertHendler(dispatch, error)
      });
  }
 
  static alertHendler(dispatch, result, isSwal = false, extraMessage = '', type = 'error') {
    if (type == 'error') {
      if (result.response) {
        const status = result.response.status
        switch (status) {

          case 500:
            if (isSwal) {
              const data = result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title: 'Delete Error',
                html: data.error,
                icon: 'error',
                showConfirmButton: true,
              })
            } else {
              const data = result.response.data
              dispatch(FloraActions.error(data.error));
            }
            break;
          case 403:
            console.log('Error 403');
            break;
          case 404:
            console.log('Error 404');
            break;
          case 401:
            const data = result.response.data
            MySwal.fire({
              title: 'Login Invalid',
              html: data.error,
              icon: 'error',
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>
              }
            })
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: 'Internal Server Error',
          html: 'Please info your Developer to fix this',
          icon: 'error',
          showConfirmButton: true,
        })
        dispatch(FloraActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    } else {
      MySwal.fire({
        title: 'Success',
        html: extraMessage,
        icon: 'success',
        showConfirmButton: true,
      })
    }
  }
}
