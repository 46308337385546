
export default class cookieManager {
    static setLanguage(lang) {
        document.cookie = 'lang=; Max-Age=-99999999;';  
        document.cookie = "lang="+lang;
    }
    static getCookie(value='') {
        let name = value+"=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
    }
    static setUserToken(result){
        const token=result.accessToken
        let expired=result.expires_at
        if(expired==undefined){
            document.cookie = `accessToken=${token}`;  
        }else{
            expired=new Date(expired);
            document.cookie = `accessToken=${token}; expires=${expired}`;  
        }
    }

    static removeUserToken(){
        document.cookie = 'accessToken=; Max-Age=-99999999;';  
    }
    static getUserToken() {
      const token=cookieManager.getCookie('accessToken');
      if(  token == undefined ||  token == null ){
        // window.location=location.origin+'/login'
      }
      return token;
    }
  }
  