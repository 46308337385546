import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Fauna from '../components/fauna';
// middlewares
import AuthMiddleware from '../reducers/auth/middleware';
import { Navigate } from 'react-router-dom';
import FaunaMiddleware from '../middleware/fauna';

const mapStateToProps = ({ auth,fauna }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  menu:fauna.list,
  data:fauna.dataFauna
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials),
    getList:()=>FaunaMiddleware.getList(),
    getData:(data)=>FaunaMiddleware.getData(data),
  }, dispatch);
};

class FaunaContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func.isRequired,
    menu:PropTypes.array.isRequired,
    data:PropTypes.any
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    menu: [],
    data:null
  }
  constructor(props) {
    super(props);
    this.state = {
      slug:null
    };
  }

  UNSAFE_componentWillMount(){
    this.props.getList()
  }
  getData(slug){
    this.props.getData(slug)
  }
  componentWillUnmount(){
    this.setState({slug:null})
  }
  render() {
    const {slugFauna}=this.props
    if(this.state.slug !=slugFauna){
      this.state.slug=slugFauna
      this.getData(slugFauna)
    }
    return (
      <Fauna
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaunaContainer);
