import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SideMenu from "../layouts/SideMenu";
import YoutubeEmbed from "../utilities/youtubeEmbed";

class Flora extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
     
      showPassword: false,
    };
    this.changeData=this.changeData.bind(this)
  }


  renderOptions(){
    const { slug, menu ,navigate} = this.props;
    let options=[];
    const type="flora"
    options.push(<option key={"rootFlora"} value={null}>Flora</option>)
    menu.map((menu, i) => (
      options.push(<option key={i+"floraOptions"} value={menu.name}>{menu.name}</option>)
    ));
    return options;
  }
  changeData(e){
    const {navigate} = this.props;
    const type="flora"
    if(e.target.value =="Flora"){
      navigate("/"+type)
    }else{
      navigate("/"+type+"/"+e.target.value)
    }
    
    // console.log(navigate);
  }

  render() {
    const { slug, menu, data ,isProcessing} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Flora</title>
        </Helmet>

        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content row">
            <div
              id="kt_app_sidebar"
              className="app-sidebar align-self-start col-3"
              data-kt-drawer="true"
              data-kt-drawer-name="app-sidebar"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '225px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
              <div
                className="card flex-grow-1 py-5"
                data-kt-sticky="true"
                data-kt-sticky-name="app-sidebar-menu-sticky"
                data-kt-sticky-offset="{default: false, xl: '500px'}"
                data-kt-sticky-width="225px"
                data-kt-sticky-left="auto"
                data-kt-sticky-top="125px"
                data-kt-sticky-animation="false"
                data-kt-sticky-zindex="95"
              >
                <div
                  className="hover-scroll-y px-1 px-lg-5"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_header, #kt_app_toolbar, #kt_app_footer"
                  data-kt-scroll-wrappers="#kt_app_main"
                  data-kt-scroll-offset="5px"
                >
                  <SideMenu menu={menu} slug={slug} type={"flora"}></SideMenu>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <select className="w-100" onChange={(e)=>this.changeData(e)}>
                {this.renderOptions()}
              </select>
            </div>
            {(!isProcessing)?
            <React.Fragment>
            {(data != null)  ? (
              <div className="card card-flush col-sm-12 col-md-12 col-lg-9 " style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <div className="card-title">{data.title}</div>
                </div>

                <div className="card-body pt-0">
                  {data.cover !=null?<div className=" text-center mb-2">
                    <img
                      src={data.cover}
                      className="img-fluid"
                      alt="Responsive image"
                    ></img>
                  </div>:null}
                  <div
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></div>
                  {data.embedId != undefined ? (
                    <YoutubeEmbed embedId={data.embedId}></YoutubeEmbed>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="card card-flush col-sm-12 col-md-12 col-lg-9 " style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <h3 className="card-title">Pengertian Flora</h3>
                </div>

                <div className="card-body pt-0">
                  <img
                    src="/images/peta flora.png"
                    alt="petaFlora"
                    className="py-4 img-fluid"
                  />
                  <div className="fs-5 fw-semibold text-gray-600">
                    <p>
                      Indonesia merupakan negara yang memiliki iklim tropis dan
                      curah hujan yang tinggi, sehingga menghasilkan banyak
                      hutan hujan tropis dan beragam flora dan faun. Beberapa
                      contoh flora di Indonesia antara lain pinus, jati, matoa,
                      mahoni, dan kayu cendana. Flora di Indonesia barat mirip
                      dengan yang ditemukan di Asia, dengan tanaman seperti
                      karet, kapur barus, dan kayu manis. Adanya bermacam macam
                      tumbuhan di suatu wilayah dapat dipengaruhi oleh faktor
                      iklim terutama curah hujan dan suhu udara. Berdasarkan
                      klasifikasi Koppen hutan di Indonesia dibedakan menjadi
                      tiga wilayah. Apa saja ? Mari kita bahas !
                    </p>
                    <ul>
                      <li>
                      <h4>Indonesia bagian barat</h4>
                        <p>
                          Bagian barat Indonesia termasuk dalam wilayah iklim AF
                          atau tropis basah. Wilayah iklim AF biasanya memiliki
                          curah hujan rata rata &plusmn; 60 milimeter per bulan.
                          Ciri flora yang ada di wilayah ini antara lain :
                        </p>
                        <ul>
                          <li>pohon besar dan tinggi,</li>
                          <li>daunnya lebar,</li>
                          <li>mereka akan menghijau sepanjang tahun</li>
                        </ul>
                        <p>
                          contohnya : Pinus, pohon beringin, meranti, rafflesia,
                          jati, mahoni, bakau, dll.
                        </p>
                        <div
                          className="card mb-5"
                          style={{ borderStyle: "solid", borderWidth: 1 }}
                        >
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                textTransform: "uppercase",
                                color: "cornflowerblue",
                              }}
                            >
                              Info menarik !
                            </h5>
                            <p className="card-text">
                              Indonesia bagian barat sering disebut juga Hutan
                              Hujan Tropis, flora pada terdapat di wilayah ini
                              dapat dimanfaatkan sebagai bahan baku industri
                              seperti mebel dan kayu lapis.
                            </p>
                            <p className="card-text">
                              Bioma ini terdapat di Sumatra, Kalimantan dan Jawa
                              bagian barat.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                      <h4>Indonesia bagian tengah</h4>
                        <p>
                          Indonesia bagian tengah termasuk dalam wilayah iklim
                          AM atau tropis sedang.Wilayah iklim AM atau tropis
                          sedang biasanya memiliki curah hujan kurang dari 60
                          milimeter per bulan dan rata rata curah hujan tahunan
                          lebih rendah dari wilayah iklim Af di wilayah ini,
                          flora yang ada dalam wilayah ini memiliki ciri ciri
                          antara lain :
                        </p>
                        <ul>
                          <li>
                            Ketinggian pohon lebih rendah daripada hutan hujan
                            tropis.
                          </li>
                          <li>
                            Daunnya akan gugur pada musim kemarau dan mulai
                            bertunas kembali pada musim hujan.
                          </li>
                        </ul>
                        <p>
                          Contoh : akasia, cendana, dan beberapa jenis bunga
                          anggrek
                        </p>
                        <div
                          className="card mb-5"
                          style={{ borderStyle: "solid", borderWidth: 1 }}
                        >
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                textTransform: "uppercase",
                                color: "cornflowerblue",
                              }}
                            >
                              Info menarik !
                            </h5>
                            <p className="card-text">
                              Indonesia bagian tengah disebut juga &ldquo;Hutan
                              musim&rdquo;, sebab terdiri dari hutan musim dan
                              hutan gugur.
                            </p>
                            <p className="card-text">
                              Bioma ini terdapat di Jawa, Nusa Tenggara,
                              Sulawesi, Sumbawa.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h4>Indonesia bagian timur</h4>
                        <p>
                          Indonesia bagian timur termasuk dalam wilayah iklim AW
                          atau tropis kering. Wilayah iklim AW biasanya memiliki
                          curah hujan kurang dari 60 milimeter per bulan dan
                          rata rata curah hujan tahunan lebih rendah dari
                          wilayah iklim lainnya. Di wilayah ini terdapat hutan
                          sabana dengan ciri ciri : terdapat padang rumput,
                          semak belukar, dan pohon pohon memiliki ketinggian
                          rendah.
                        </p>
                        <p>
                          Contoh : Pohon sagu, pohon nipah, rasamala, matoa,
                          cendana
                        </p>
                        <div
                          className="card mb-5"
                          style={{ borderStyle: "solid", borderWidth: 1 }}
                        >
                          <div className="card-body">
                            <h5
                              className="card-title"
                              style={{
                                textTransform: "uppercase",
                                color: "cornflowerblue",
                              }}
                            >
                              Info menarik !
                            </h5>
                            <p className="card-text">
                              Indonesia bagian timur sering disebut juga
                              &ldquo;Sabana&rdquo;.
                            </p>
                            <p className="card-text">
                              Persebarannya terdapat di Nusa Tenggara, Sulawesi
                              Selatan, Maluku dan Papua.
                            </p>
                            <p className="card-text">
                              Sabana dapat dimanfaatkan masyarakat dalam
                              kegiatan peternakan.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    
      
                      <section>
                      <img src="/images/tanaman produksi.jpg" alt="tanaman produksi" className=" px-12 py-4 img-fluid"/>
                      <h4>Tanaman Produksi</h4>
                      
                        <p>
                          Tanaman produksi merupakan tanaman yang ditanam dengan tujuan produksi, antara lain menghasilkan bahan pangan dan serat bahan bakar. Dalam pertanian, tanaman produksi merupakan tanaman yang ditanam untuk tujuan ekonomi, seperti menghasilkan hasil panen yang dapat dijual. Tanaman produksi antara lain :
                        </p>
                        <ol type="a">
                          <li><div><b>Tanaman pangan</b></div>
                              <img src="/images/tanaman pangan.jpg" alt="tanaman pangan" className=" px-12 py-4 img-fluid"/>
                              <p>
                                Tanaman pangan merupakan tanaman yang memiliki kandungan karbohidrat tinggi, seperti jagung, padi, kentang dan ubi kayu.
                              </p>
                          </li>
                          <li><div><b>Tanaman Perkebunan</b></div>
                              <img src="/images/tanaman perkebunan.jpg" alt="tanaman perkebunan" className=" px-12 py-4 img-fluid"/>
                              <p>
                              Tanaman perkebunan merupakan tanaman yang ditanam dalam jumlah banyak dengan tujua untuk meningkatkan produksi serta kualitas hasilnya, contohnya karet, teh, kelapa, sedap malam dan rempah -rempah.
                              </p>
                          </li>
                          <li><div><b>Tanaman sayuran</b></div>
                              <img src="/images/tanaman sayuran.jpg" alt="tanaman sayuran" className=" px-12 py-4 img-fluid"/>
                              <p>
                              Tanaman sayuran merupakan tanaman yang dihasilakan untuk dikonsumsi sebagai sayuran, seperti bawang pray, sawi, terong dan kubis.
                              </p>
                          </li>
                          <li><div><b>Tanaman obat</b></div>
                              <img src="/images/tanaman obat.jpg" alt="tanaman obat" className=" px-12 py-4 img-fluid"/>
                              <p>
                              Tanaman obat merupakan yang dihasilkan untuk dijadikan obat-obatan.
                              </p>
                          </li>
                        </ol >
                        
                      </section>
                      <section>
                        <img src="/images/tanaman industri.jpg" alt="tanaman industri" className=" px-12 py-4 img-fluid"/>
                      <h4>Tanaman Industri</h4>
                      
                        <p>
                        Tanaman industri merupakan komoditas yang dapat memajukan perekonomian Indonesia karena nilainya yang tinggi di pasaran dan Indonesia mampu mengekspornya ke negara lain. Indonesia memiliki beragam tanaman yang dapat diamnfaatkan berdasarkan jenisnya, salah satunya tanaman industri.
                        </p>
                        <p>
                        Tanaman industri merupakan tumbuhan yang dapat dimanfaatkan untuk keperluan industri, seperti produksi bahan kimia bahan baku, produk farmasi, tekstil, makanan, dan minuman. Tanaman industri merupakan komoditas tanaman yang dapat memajukan perekonomian Indonesia karena memiliki nilai jual yang tinggi dan dapat diekspor ke luar negeri.
                        </p>
                        <p>
                        Contoh tanaman industri yang banyak ditanam di Indonesia antara lain cendana, eboni, jati, damar, mahoni, ulin dan pinus.
                        </p>
                      </section>
                  </div>
                </div>
              </div>
            )}
            </React.Fragment>:null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Flora;
