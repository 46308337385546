import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HutanContainer from "../../containers/HutanContainer";
export const Hutan = (props)=>{
  let params=useParams();
  let slug=params.slug;
  let navigate=useNavigate();
  return (
      <HutanContainer slugHutan={slug} navigate={navigate}/>
  )
}

export default Hutan;
