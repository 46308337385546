import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SideMenu from "../layouts/SideMenu";
import VideoPlayer from "../utilities/VideoJS";
import YoutubeEmbed from "../utilities/youtubeEmbed";
import { Link } from "react-router-dom";
class Fauna extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.changeData=this.changeData.bind(this)
  }


  renderOptions(){
    const { slug, menu ,navigate} = this.props;
    let options=[];
    const type="fauna"
    options.push(<option key={"rootFauna"} value={null}>Fauna</option>)
    menu.map((menu, i) => (
      options.push(<option key={i+"faunaOptions"} value={menu.name}>{menu.name}</option>)
    ));
    return options;
  }
  changeData(e){
    console.log(e.target.value)
    const {navigate} = this.props;
    const type="fauna"
    if(e.target.value =='Fauna'){
      navigate("/"+type)
    }else{
      navigate("/"+type+"/"+e.target.value)
    }
    
    // console.log(navigate);
  }

  render() {
    const { slug, menu, data,isProcessing } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Fauna</title>
        </Helmet>

        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content row">
            <div
              id="kt_app_sidebar"
              className="app-sidebar align-self-start col-3"
              data-kt-drawer="true"
              data-kt-drawer-name="app-sidebar"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '225px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
              <div
                className="card flex-grow-1 py-5"
                data-kt-sticky="true"
                data-kt-sticky-name="app-sidebar-menu-sticky"
                data-kt-sticky-offset="{default: false, xl: '500px'}"
                data-kt-sticky-width="225px"
                data-kt-sticky-left="auto"
                data-kt-sticky-top="125px"
                data-kt-sticky-animation="false"
                data-kt-sticky-zindex="95"
              >
                <div
                  className="hover-scroll-y px-1 px-lg-5"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_header, #kt_app_toolbar, #kt_app_footer"
                  data-kt-scroll-wrappers="#kt_app_main"
                  data-kt-scroll-offset="5px"
                >
                  <SideMenu menu={menu} slug={slug} type={"fauna"}></SideMenu>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <select className="w-100"onChange={(e)=>this.changeData(e)}>
                {this.renderOptions()}
              </select>
            </div>
            
            {data != null ? (
              <div className="card card-flush col-sm-12 col-md-12 col-lg-9" style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <div className="card-title">{data.title}</div>
                </div>

                <div className="card-body pt-0">
                  <div className="mb-2 text-center">
                    <img
                      src={data.cover}
                      className="img-fluid"
                      alt="Responsive image"
                    ></img>
                  </div>
                  <div
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></div>
                  {data.embedId != undefined ? (
                    <YoutubeEmbed embedId={data.embedId}></YoutubeEmbed>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="card card-flush col-sm-12 col-md-12 col-lg-9 " style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <h3 className="card-title">Pengertian Fauna</h3>
                </div>
                <div className="card-body pt-0">
                  <img src="/images/peta fauna.png" alt="petaFauna" className="py-4 img-fluid"/>
                  <div className="fs-5 fw-semibold text-gray-600">
                    <p>
                      Indonesia merupakan salah satu dari tiga negara terbesar
                      di dunia dengan keanekaragaman flora dan fauna yang
                      tinggi. Fauna di Indonesia sangat beragam dan mencakup
                      banyak spesies endemik seperti komodo, bekantan, dan tapir
                      melayu. Fauna di Indonesia dibagi menjadi tiga wilayah:
                      Indonesia bagian Barat, Indonesia bagian Tengah, Indonesia
                      bagian Timur, yang dipisahkan oleh garis Wallace dan
                      Weber. Hal ini dikemukakan oleh Alfred Russel Wallace.
                      Mari kita bahas satu persatu !
                    </p>
                    <ol type="1">
                      <li>
                        Indonesia bagian barat
                        <p>
                          Pada wilayah Indonesia bagian barat terdapat fauna
                          yang mirip di benua Asia oleh karena itu biasanya
                          disebut &ldquo;Fauna Asiatis&rdquo;. Dengan ciri-ciri
                          dijumpai mamalia berukuran besar, banyak jenis hewan
                          air tawar, terdapat banyak tipe kera atau primata
                          orang utan, siamang, monyet ekor panjang dan tidak
                          dijumpai hewan berkantong. Seperti : harimau, beruang,
                          gajah, badak, tapir, trenggiling, beberapa jenis
                          buaya, dan pesut.
                        </p>
                        <strong><em>Catatan</em></strong>
                        <p>
                          Indonesia bagian barat ini meliputi wilayah Sumatra,
                          Jawa, dan Kalimantan.
                        </p>
                      </li>
                      <li>
                        Indonesia bagian tengah
                        <p>
                          Fauna di wilayah Indonesia bagian tengah bersifat khas
                          dan berbeda dengan hewan di Indonesia bagian barat dan
                          timur. Fauna Indonesia bagian tengah biasanya disebut
                          &ldquo;Fauna peralihan&rdquo;. Contoh fauna Indonesia
                          bagian tengah adalah sebagai berikut : Biawak dan
                          komodo, anoa, babi rusa, burung maleo
                        </p>
                        <strong><em>Catatan</em></strong>
                        <p>
                          Persebarannya meliputi kawasan Maluku, Sulawesi, dan
                          Nusa Tenggara.
                        </p>
                        <p>
                          Fauna peralihan dibatasi oleh garis Wallace dan garis
                          Webber.
                        </p>
                        <div
                            className="card mb-5"
                            style={{ borderStyle: "solid", borderWidth: 1 }}
                          >
                            <div className="card-body">
                              <h5
                                className="card-title"
                                style={{
                                  textTransform: "uppercase",
                                  color:'cornflowerblue'
                                }}
                              >
                                Tahu kah kamu?
                              </h5>
                              <p className="card-text">
                              Garis Wallace ini membatasi fauna yang berada di
                              dangkalan sunda kemudian garis ini ditarik dari
                              sebelah timur Filipina melalui Selat Makasar hingga
                              perbatasan antara Pulau Bali dan Pulau Lombok.
                              Sedangkan Garis Webber membatasi fauna dangkalan sahul
                              mencakup Maluku dan Papua.
                              </p>
                    
                            </div>
                          </div>
                      </li>
                      <li>
                        Indonesia bagian timur
                        <p>
                          Fauna di wilayah Indonesia bagian timur mirip dengan
                          fauna di Benua Australia oleh karena itu sering
                          disebut &ldquo;Fauna Australis&rdquo;. Fauna ini
                          tersebar di dataran sahul atau wilayah timur Indonesia
                          seperti Papua, contoh fauna austalis yaitu :
                        </p>
                        <p>
                          Playtypus, Kangkuru, burung cendrawasih, dan lain lain
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>)
              }
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Fauna;
