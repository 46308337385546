import React, { Component } from "react";
import { connect } from "react-redux";
import {Navigate, Outlet } from "react-router-dom";
import { bindActionCreators } from "redux";
import Footer from '../../components/layouts/Footer'
import Header from '../../components/layouts/Header'
import useScript from "../../components/utilities/useScript";
import AuthMiddleware from "../../reducers/auth/middleware";

const mapStateToProps = ({ auth}) => ({
  isAuthenticated: auth.isAuthenticated,
  authUser:auth.authUser
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    runlogout:()=>AuthMiddleware.logout()
  }, dispatch);
};

class Layout extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount () {
    useScript("/assets/js/SmoothScroll.min.js");
    useScript("/assets/js/bootstrap.bundle.min.js");
    useScript("/assets/js/scripts.bundle.js");
    useScript("/assets/js/widgets.bundle.js");
    useScript("/assets/plugins/global/plugins.bundle.js");
  }
  
  render() {
    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            <Header {...this.props}/>
            <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper"> 
              <div className="app-container container-xxl d-flex">
			          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <Outlet />
                  <Footer/>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(Layout);
