import i18n from "../../i18n";

const Menu=[
    
    {   
        label:i18n.t('Master'),
        link:'#MasterGroup',
        id:'MasterGroup',
        menuName:"Master",
        visible:true,
        childs:[
            {
                label:i18n.t('Utility'),
                link:'#UtilityMenu',
                id:'UtilityMenu',
                menuName:"Utility",
                visible:true,
                childs:[
                    {
                        label:i18n.t('Customer'),
                        link:'#CustomerCategory',
                        id:'CustomerCategory',
                        menuName:"Customer Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('Customer Type'),
                                link:'/master/customer-type',
                                id:'CustomerType',
                                menuName:"customer-type",
                                visible:true,
                            },
                            {
                                label:i18n.t('Customer List'),
                                link:'/master/customer',
                                id:'CustomerList',
                                menuName:"customer",
                                visible:true,
                            },
                            {
                                label:i18n.t('Customer Hierarchy'),
                                link:'/master/customer-hierarchy',
                                id:'CustomerHierarchy',
                                menuName:"customer-hierarchy",
                                visible:true,
                            },
                            {
                                label:i18n.t('Setup Hierarchy'),
                                link:'/master/setup-hierarchy',
                                id:'SetupHierarchy',
                                menuName:"setup-hierarchy",
                                visible:true,
                            },
                            {
                                label:i18n.t('Tax List'),
                                link:'/master/tax',
                                id:'TaxList',
                                menuName:"tax",
                                visible:true,
                            },
                        ]
                    },	
                        {
                        label:i18n.t('Warehouse'),
                        link:'#WarehouseCategory',
                        id:'WarehouseCategory',
                        menuName:"Warehouse Category",
                        visible:true,
                        active:false,
                        childs:[
                                {
                                    label:i18n.t('Warehouse List'),
                                    link:'/master/warehouse',
                                    id:'WarehouseList',
                                    menuName:"warehouse",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Sub Warehouse List'),
                                    link:'/master/sub-warehouse',
                                    id:'SubWarehouseList',
                                    menuName:"sub-warehouse",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Setup Warehouse List'),
                                    link:'/master/setup-warehouse',
                                    id:'SetupWarehouseList',
                                    menuName:"setup-warehouse",
                                    visible:true,
                                }
                        ]
                    },
                    {
                        label:i18n.t('Supplier'),
                        link:'#SupplierCategory',
                        id:'SupplierCategory',
                        menuName:"Supplier Category",
                        active:false,
                        visible:true,
                        childs:[
                                {
                                    label:i18n.t('Supplier List'),
                                    link:'/master/supplier',
                                    id:'supplierList',
                                    menuName:"supplier",
                                    visible:true,
                                }
                        ]
                    },
                    {
                        label:i18n.t('Item'),
                        link:'#ItemCategory',
                        id:'ItemCategory',
                        menuName:"Item Category",
                        active:false,
                        visible:true,
                        childs:[
                                {
                                    label:i18n.t('Unit List'),
                                    link:'/master/unit',
                                    id:'unitList',
                                    menuName:"unit",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Item Type List'),
                                    link:'/master/item-type',
                                    id:'itemtypeList',
                                    menuName:"ItemType",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Branch List'),
                                    link:'/master/branch',
                                    id:'branchList',
                                    menuName:"branch",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Store List'),
                                    link:'/master/store',
                                    id:'storeList',
                                    menuName:"store",
                                    visible:true,
                                },
                                {
                                    label:i18n.t('Item List'),
                                    link:'/master/item',
                                    id:'itemList',
                                    menuName:"item",
                                    visible:true,
                                },
                        ]
                    },
                ]
            },
            {
                label:i18n.t('Admin'),
                link:'#AdminMenu',
                id:'AdminMenu',
                menuName:"Admin",
                visible:true,
                childs:[
                    {
                        label:i18n.t('User'),
                        link:'#UserCategory',
                        id:'UserCategory',
                        menuName:"User Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('Menu'),
                                link:'/admin/menu',
                                id:'Menu',
                                menuName:"menu",
                                visible:true,
                            },
                            {
                                label:i18n.t('Action'),
                                link:'/admin/action',
                                id:'Action',
                                menuName:"action",
                                visible:true,
                            },
                            {
                                label:i18n.t('Function'),
                                link:'/admin/function',
                                id:'Function',
                                menuName:"function",
                                visible:true,
                            },
                            {
                                label:i18n.t('Role'),
                                link:'/admin/role',
                                id:'Role',
                                menuName:"role",
                                visible:false,
                            },
                            {
                                label:i18n.t('Assignment'),
                                link:'/admin/assignment',
                                id:'Assignment',
                                menuName:"assignment",
                                visible:false,
                            },
                        ]
                    },
                ]        
            },
            {
                label:i18n.t('Human Resource'),
                link:'#HrMenu',
                id:'HrMenu',
                menuName:"Human Resource",
                visible:true,
                childs:[
                    {
                        label:i18n.t('Master'),
                        link:'#MasterHrCategory',
                        id:'MasterHrCategory',
                        menuName:"Master Hr Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('Division'),
                                link:'/hr/division',
                                id:'divison',
                                menuName:"divison",
                                visible:true,
                            },
                            {
                                label:i18n.t('Position'),
                                link:'/hr/position',
                                id:'position',
                                menuName:"position",
                                visible:true,
                            },
                            {
                                label:i18n.t('Category Employee'),
                                link:'/hr/category-employee',
                                id:'categoryEmployee',
                                menuName:"categoryEmployee",
                                visible:true,
                            },
                            {
                                label:i18n.t('Holiday'),
                                link:'/hr/holiday',
                                id:'holiday',
                                menuName:"holiday",
                                visible:true,
                            },
                            {
                                label:i18n.t('Attandance'),
                                link:'/hr/attandance',
                                id:'attandance',
                                menuName:"attandance",
                                visible:true,
                            },
                        ]
                    },
                    {
                        label:i18n.t('Salary'),
                        link:'#SalaryCategory',
                        id:'SalaryCategory',
                        menuName:"Salary Category",
                        visible:true,
                        active:false,
                        childs:[
                            {
                                label:i18n.t('Salary Type'),
                                link:'/hr/salary-type',
                                id:'salaryType',
                                menuName:"salaryType",
                                visible:true,
                            },
                            {
                                label:i18n.t('Salary Cuts'),
                                link:'/hr/salary-cuts',
                                id:'salaryCuts',
                                menuName:"salaryCuts",
                                visible:true,
                            },
                            {
                                label:i18n.t('Salary Cuts Detail'),
                                link:'/hr/salary-cuts-detail',
                                id:'salaryCutsDetail',
                                menuName:"salaryCutsDetail",
                                visible:true,
                            },
                            {
                                label:i18n.t('Salary Class'),
                                link:'/hr/salary-class',
                                id:'salaryClass',
                                menuName:"salaryClass",
                                visible:true,
                            },
                        ]
                    },
                    {
                        label:i18n.t('Allowance'),
                        link:'#AllowanceCategory',
                        id:'AllowanceCategory',
                        menuName:"Allowance Category",
                        visible:true,
                        active:false,
                        childs:[
                            {
                                label:i18n.t('Allowance'),
                                link:'/hr/allowance',
                                id:'allowance',
                                menuName:"allowance",
                                visible:true,
                            },
                            {
                                label:i18n.t('Allowance Detail'),
                                link:'/hr/allowance-detail',
                                id:'allowanceDetail',
                                menuName:"allowanceDetail",
                                visible:true,
                            },
                        ]
                    }
                ]        
            }     
        ]
    },
    {   
        label:i18n.t('Transaction'),
        link:'#TransactionGroup',
        id:'TransactionGroup',
        menuName:"Transaction",
        visible:true,
        childs:[
            {
                label:i18n.t('Transaction'),
                link:'#TransactionMenu',
                id:'TransactionMenu',
                menuName:"TransactionMenu",
                visible:true,
                childs:[
                    {
                        label:i18n.t('Transaction'),
                        link:'#TransactionCategory',
                        id:'TransactionCategory',
                        menuName:"Transaction Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('Offering'),
                                link:'/pembelian/offering',
                                id:'GoodsOffer',
                                visible:true,
                                menuName:"goodsoffer",
                            },
                            {
                                label:i18n.t('Purchase Request'),
                                link:'/pembelian/purchase-request',
                                id:'PurchaseRequest',
                                visible:true,
                                menuName:"purchaserequest",
                            },
                            {
                                label:i18n.t('Recap Purchase Request'),
                                link:'/pembelian/purchase-recap',
                                id:'PurchaseRecap',
                                visible:true,
                                menuName:"purchaserecap",
                            },
                            {
                                label:i18n.t('Purchase Order'),
                                link:'/pembelian/purchase-order',
                                id:'PurchaseOrder',
                                visible:true,
                                menuName:"purchaseorder",
                            },
                            {
                                label:i18n.t('Receiving'),
                                link:'/pembelian/receiving',
                                id:'Goods Receipt',
                                visible:true,
                                menuName:"goodsreceipt",
                            },  
                            {
                                label:i18n.t('Invoice'),
                                link:'/pembelian/invoice',
                                id:'Invoice',
                                visible:true,
                                menuName:"invoice",
                            },  
                        ]
                    },	
                    {
                        label:i18n.t('Payment'),
                        link:'#PaymentCategory',
                        id:'PaymentCategory',
                        menuName:"Payment Category",
                        visible:true,
                        active:false,
                        childs:[
                            {
                                label:i18n.t('Payment Supplier'),
                                link:'/pembelian/payment',
                                id:'payment',
                                visible:true,
                                menuName:"PaymentSupplier",
                            },
                        ]
                    },
                    {
                        label:i18n.t('Log'),
                        link:'#LogCategory',
                        id:'LogCategory',
                        menuName:"Log Category",
                        visible:true,
                        active:false,
                        childs:[
                            {
                                label:i18n.t('Request Print'),
                                link:'/log/print',
                                id:'logPrint',
                                visible:true,
                                menuName:"logPrint",
                            },
                            {
                                label:i18n.t('Approval Customer'),
                                link:'/log/customer-approval',
                                id:'logApprovalCustomer',
                                visible:true,
                                menuName:"logApprovalCustomer",
                            },
                            {
                                label:i18n.t('Transaction Update'),
                                link:'/log/transaction-update',
                                id:'logTransactionUpdate',
                                visible:true,
                                menuName:"logTransactionUpdate",
                            },
                        ]
                    },
                ]
            },
            {
                label:i18n.t('Warehouse Transfer'),
                link:'#WarehouseTransfer',
                id:'WarehouseTransfer',
                menuName:"WarehouseTransfer",
                active:false,
                visible:true,
                childs:[
                    {
                        label:i18n.t('Warehouse Transfer'),
                        link:'#WarehouseCategory',
                        id:'WarehouseCategory',
                        menuName:"Warehouse Category",
                        active:true,
                        visible:true,
                        childs:[

                            {
                                label:i18n.t('Warehouse Out'),
                                link:'/warehouse/warehouse-out',
                                id:'WarehouseOut',
                                visible:true,
                                menuName:"Warehouse Out",
                            },
                            {
                                label:i18n.t('Warehouse In'),
                                link:'/warehouse/warehouse-in',
                                id:'WarehouseIn',
                                visible:true,
                                menuName:"Warehouse In",
                            },
                        ]
                        
                    },

                ]

             },
             {
                label:i18n.t('Stock & Mutation'),
                link:'#stockMutasi',
                id:'stockMutasi',
                menuName:"stockMutasi",
                active:false,
                visible:true,
                childs:[
                    {
                        label:i18n.t('Mutation'),
                        link:'#MutationCategory',
                        id:'MutationCategory',
                        menuName:"Mutation Category",
                        active:true,
                        visible:true,
                        childs:[

                            {
                                label:i18n.t('Mutation'),
                                link:'/mutation',
                                id:'mutation',
                                visible:true,
                                menuName:"mutation",
                            },
                        ]
                        
                    },

                ]

             }
                        
        ]
    },
    {   
        label:('Accounting'),
        link:'#AccountingGroup',
        id:'AccountingGroup',
        menuName:"Accounting",
        visible:true,
        childs:[
            {
                label:i18n.t('Accounting'),
                link:'#AccountingMenu',
                id:'AccountingMenu',
                menuName:"AccountingMenu",
                visible:true,
                childs:[
                     {
                        label:i18n.t('COA'),
                        link:'#COACategory',
                        id:'COACategory',
                        menuName:"COA Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('Bank'),
                                link:'/accounting/bank',
                                id:'Bank',
                                visible:true,
                                menuName:"bank",
                            },     
                            {
                                label:i18n.t('COA 1'),
                                link:'/accounting/coa1',
                                id:'Coa1',
                                visible:true,
                                menuName:"coa1",
                            },
                           
                            {
                                label:i18n.t('COA 2'),
                                link:'/accounting/coa2',
                                id:'Coa2',
                                visible:true,
                                menuName:"coa2",
                            },
                            {
                                label:i18n.t('COA 3'),
                                link:'/accounting/coa3',
                                id:'Coa3',
                                visible:true,
                                menuName:"coa3",
                            },
                            {
                                label:i18n.t('COA 4'),
                                link:'/accounting/coa4',
                                id:'Coa4',
                                visible:true,
                                menuName:"coa4",
                            },
                            {
                                label:i18n.t('COA 5'),
                                link:'/accounting/coa5',
                                id:'Coa5',
                                visible:true,
                                menuName:"coa5",
                            },

                                ]
                            },
                            {
                                label:i18n.t('Configuration'),
                                    link:'#ConfigurationCategory',
                                    id:'ConfigurationCategory',
                                    menuName:"Configuration Category",
                                    active:false,
                                    visible:true,
                                    childs:[
                                        {
                                            label:i18n.t('Configuration'),
                                            link:'/accounting/configuration',
                                            id:'Configuration',
                                            visible:true,
                                            menuName:"configuration",
                                        },    



                                    ]
                                },
                                {
                                label:i18n.t('Finance'),
                                    link:'#FinanceCategory',
                                    id:'FinanceCategory',
                                    menuName:"Finance Category",
                                    active:false,
                                    visible:true,
                                    childs:[
                                        {
                                            label:i18n.t('General Ledger'),
                                            link:'/accounting/journal',
                                            id:'GeneralLedger',
                                            visible:true,
                                            menuName:"journal",
                                        },  
                                        {
                                            label:i18n.t('Cash / Bank'),
                                            link:'/accounting/cash-bank',
                                            id:'CashBank',
                                            visible:true,
                                            menuName:"cashbank",
                                        },{
                                            label:i18n.t('Purchase Return'),
                                            link:'/pembelian/purchase-return',
                                            id:'PurchaseReturn',
                                            visible:true,
                                            menuName:"purchasereturn",
                                        },{
                                            label:i18n.t('Memo In'),
                                            link:'/accounting/memo-in',
                                            id:'MemoIn',
                                            visible:true,
                                            menuName:"memoin",
                                        },{
                                            label:i18n.t('Memo Out'),
                                            link:'/accounting/memo-out',
                                            id:'MemoOut',
                                            visible:true,
                                            menuName:"memoout",
                                        },  



                                    ]
                                },
                            
                        ]
                    },

                    
        ]
    },
    {   
        label:i18n.t('Sales'),
        link:'#SalesGroup',
        id:'SalesGroup',
        menuName:"Sales",
        visible:true,
        childs:[
                {
                label:i18n.t('Sales'),
                link:'#SalesMenu',
                id:'SalesMenu',
                menuName:"SalesMenu",
                visible:true,
                childs:[
                     {
                        label:i18n.t('Customer'),
                        link:'#CustomerCategory',
                        id:'CustomerCategory',
                        menuName:"Customer Category",
                        visible:true,
                        active:true,
                        childs:[
                            {
                                label:i18n.t('New Customer'),
                                link:'/sales/customer-temp',
                                id:'NewCustomer',
                                visible:true,
                                menuName:"new customer",
                            },  
                        ],
                    },
                    {
                        label:i18n.t('Transaction Sales'),
                        link:'#TransactionSalesCategory',
                        id:'TransactionSalesCategory',
                        menuName:"Transaction Sales Category",
                        visible:true,
                        active:false,
                        childs:[
                            {
                                label:i18n.t('Selling Price'),
                                link:'/sales/selling-price',
                                id:'SellingPrice',
                                visible:true,
                                menuName:"selling price",
                            },  
                            {
                                label:i18n.t('Sales Order'),
                                link:'/sales/sales-order',
                                id:'SalesOrder',
                                visible:true,
                                menuName:"sales order",
                            },  
                        ],
                    },
                ],
            },
        ],
    },

    
   
];



export default Menu;