const ID ={
    "Customer Type":"Tipe Pelanggan",
    "Language":"Bahasa",
    "Select Status":'Pilih Status',

    "Offering":"Penawaran",
    "Receiving":"Penerimaan Barang",
    "Mutation":"Mutasi Barang",

    "Purchase Request No.":"No Permintaan",
    "No. Request":"No Permintaan",

    "Waiting For Approval":"Menunggung Pengadaan",
    "Procurement":"Pengadaan",
    "PR Rejected":"Ditolak",
    "PO Rejected":"Order Ditolak",
    "Waiting Center Approval":"Menunggu Persetujuan HO",
    "Waiting Receiving Item":"Menunggu Penerimaan Barang",
    "Waiting For Payment":"Menunggu Pembayaran",
    "Done":"Selesai",
    "Warehouse Trasnfer":"Pindah Gudang",

    "PO No":"No Order",
    "Date PO":"Tanggal Order",
    "Date Delivery":"Tanggal Kirim",
    "Discount Percent":"Diskon (%)",
    "Discount":"Diskon (Rp)",
    "Price Nett":"Harga",
    "Price Total":"Total Harga",
    "Total":"Total Harga",
    "Code Item":"Kode Item",

    "No PO":"No Order",
    "Band No":"Banderol",
    "Reamining":"Remaining Qty",
    "Qty":"Qty Received",
    "Receiving (PB)":"Goods Receipt",
    "No. PB":"Receiving No.",
    "Code":"Item Code",
    "No. Band": "Banderol",
    "Description":"Note",
    "Document No":"Document No",
    "Delivery Date":"Delivery Date",

    "Config Name":"Nama Konfigurasi",
    "Configuration" : "Konfigurasi"
}
export default ID;
