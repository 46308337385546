import React from "react";

import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import  App  from "./routes/App";
import { configureAppStore } from "./store/configureStore";
import "./styles/app.scss";
const store = configureAppStore();

const container = document.getElementById("root");
const root = createRoot(container); 

const supportsHistory = "pushState" in window.history;
root.render(
  <Provider store={store}>
    <BrowserRouter history={!supportsHistory} keyLength={12} >
      <App />
    </BrowserRouter>
  </Provider>,
  
);
