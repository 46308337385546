import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SideMenu from "../layouts/SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: "admin",
      password: "qwerty",
      showPassword: false,
      menu: [
        {
          url: "#katapengantar",
          name: "Kata Pengantar",
        },
        {
          url: "#pengertian",
          name: "ENSIKLOPEDIA FLORA DAN FAUNA INDONESIA",
        },
        {
          url: "#pengenalan",
          name: "Mengenal Flora & Fauna",
        },
        {
          url: "#penulis",
          name: "Tentang Penulis",
        },
      ],
      slug: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const credentials = {
      username: this.state.username,
      password: this.state.password,
      domain: this.state.domain,
    };
    this.props.signin(credentials);
  }

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content row">
          <div className="col-12 text-center"><img src="/images/Ensiklopedia Digital.png" className="img-fluid" alt="img-backjdl" style={{width:"100%"}}/></div>
            <div
              id="kt_app_sidebar"
              className="app-sidebar align-self-start col-3"
              data-kt-drawer="true"
              data-kt-drawer-name="app-sidebar"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '225px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
              <div
                className="card flex-grow-1 py-5"
                data-kt-sticky="true"
                data-kt-sticky-name="app-sidebar-menu-sticky"
                data-kt-sticky-offset="{default: false, xl: '500px'}"
                data-kt-sticky-width="225px"
                data-kt-sticky-left="auto"
                data-kt-sticky-top="125px"
                data-kt-sticky-animation="false"
                data-kt-sticky-zindex="95"
              >
                <div
                  className="hover-scroll-y px-1 px-lg-5"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_header, #kt_app_toolbar, #kt_app_footer"
                  data-kt-scroll-wrappers="#kt_app_main"
                  data-kt-scroll-offset="5px"
                >
                  <div
                    id="kt_app_sidebar_menu"
                    data-kt-menu="true"
                    className="menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6"
                  >
                    <div className="menu-item">
                      <Link className="menu-link" to={"/"}>
                        <div className="menu-content">
                          <span
                            className="menu-section fs-5 fw-bolder ps-1 py-1"
                            style={{ textTransform: "capitalize" }}
                          >
                            Home
                          </span>
                        </div>
                      </Link>
                    </div>
                    {this.state.menu.map((menu, i) => (
                      <div className="menu-item" key={"menu" + i}>
                        <Link className="menu-link" to={"/" + menu.url}>
                          <span className="menu-icon">
                            <FontAwesomeIcon icon={faDotCircle} />
                          </span>
                          <span className="menu-title">{menu.name}</span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="card my-10" id="katapengantar">
                <section className="fs-6 fw-semibold text-gray-600 p-8">Sumber belajar yang baik akan membantu peserta didik dalam meningkatkan kemampuan membaca dan menulis. Oleh karena itu, penting bagi peserta didik untuk memanfaatkan sumber belajar dengan baik dan rajin membaca. Dengan membaca, peserta didik dapat meningkatkan kreativitas dan inovasi dalam pengetahuan. Selamat membaca dan semoga sukses dalam belajar !.</section>
                  
                <div className="card-header">
                  <h3 className="card-title">Kata Pengantar</h3>
                </div>
                <div className="card-body py-2">
                  <section className="fs-6 fw-semibold text-gray-600 pb-6">
                    <div className="col-12">
                      Alhamdulillah, segala puji dan syukur penulis panjatkan
                      kehadirat Allah SWT karena ensiklopedia ini telah selesai
                      disusun. Ensiklopedia ini disusun sebagai sumber belajar
                      agar dapat membantu para peserta didik dalam mempelajari
                      materi sebaran flora dan fauna Indonesia berdasarkan
                      karakteristik ekosistem serta mempermudah mempelajari
                      materi sebaran flora dan fauna Indonesia dan Dunia
                      berdasarkan karakteristik ekosistem terutama bagi secara
                      umum yang belum mengenal persebaran flora dan fauna
                      Indonesia itu sendiri.
                    </div>
                    <div className="col-12">
                      Penulis pun menyadari jika didalam penyusunan ensiklopedia
                      ini mempunyai kekurangan, namun penulis meyakini
                      sepenuhnya bahwa sekecil apapun kekurangan ensiklopedia
                      ini tetap akan memberikan sebuah manfaat bagi pembaca.
                      Akhir kata untuk penyempurnaan buku ini, maka kritik dan
                      saran dari pembaca sangatlah berguna untuk penulis
                      kedepannya.
                    </div>
                  </section>
                </div>
              </div>
              <div className="card my-10">
                <div className="card-header">
                  <h3 className="card-title text-center">
                    ENSIKLOPEDIA FLORA DAN FAUNA INDONESIA
                  </h3>
                </div>
                <div className="card-body py-2">
                  <section className="fs-6 fw-semibold text-gray-600">
                    <div className="col-12 text-center"
                    ><img src="/images/backjdl.jpg" className="img-fluid" alt="img-backjdl" /></div></section>
                  <section
                    id="pengertian"
                    className="fs-6 fw-semibold text-gray-600 pb-6"
                  >
                    <div className="fs-5 fw-semibold text-gray-600">
                      <ol type="A">
                        <li>
                          Apakah Flora dan Fauna itu ?
                          <p>
                            Flora merupakan tanaman, yang terdiri dari berbagai macam jenis tanaman yang ada di muka bumi ini. Dan Fauna merupakan arti dari hewan yang hidup di muka bumi.
                          </p>
                        </li>
                        <li>
                          Bagaimana persebaran flora fauna di Bumi?
                          <p>
                            Masih ingatkah kalian pada pembelajaran Geografi
                            yang membahas mengenai sejarah perkembangan bumi?
                          </p>
                          <p>
                            Pada 280-225 juta tahun lalu, semua benua yang ada
                            di bumi masih bergabung dalam satu daratan yang
                            sangat luas yang disebut dengan
                            &ldquo;Pangea&rdquo;. Namun sekitar 200 juta tahun
                            yang lalu pangea terbelah lagi menjadi dua bagian
                            yang disebut &ldquo;Gondwana&rdquo; dan
                            &ldquo;Laurasia&rdquo;. &nbsp;
                          </p>
                          <p>
                            Gondwana kemudian membelah lagi membentuk Benua
                            Afrika, Antartika, Australia, Amerika Selatan, dan
                            Sub-benua India. Sedangkan Laurasia terbelah menjadi
                            Eurasia dan Amerika Utara. Pada saat benua ini
                            terbelah maka beberapa samudra baru muncul
                            diantaranya.
                          </p>
                          <div
                            className="card "
                            style={{ borderStyle: "solid", borderWidth: 1 }}
                          >
                            <div className="card-body">
                              <h5
                                className="card-title "
                                style={{
                                  color: "cornflowerblue",
                                  textTransform: "uppercase",
                                }}
                              >
                                Informasi Menarik
                              </h5>
                              <p className="card-text">
                                Rotasi bumi juga memengaruhi sebagian benua
                                terpisah loh!
                              </p>
                              <p className="card-text">
                                Teori sejarah bumi diungkapkan oleh Alfred
                                Wegener, yang merupakan ahli meteorologi dari
                                Jerman, pada tahun 1912.
                              </p>
                            </div>
                          </div>
                          <p className="pt-6">
                            Pada masa Benua Laurasia dan Gondwana persebaran
                            flora dan fauna di muka bumi dipengaruhi oleh 3
                            faktor utama, yaitu :
                          </p>
                          <ol>
                            <li>
                              Penyebab persebaran
                              <ol type="a">
                                <li>tekanan populasi</li>
                                <li>persaingan</li>
                                <li>perubahan habitat</li>
                              </ol>
                            </li>

                            <li>
                              Sarana penyebaran
                              <ol type="a">
                                <li>udara</li>
                                <li>air</li>
                              </ol>
                            </li>
                            <li>
                              Hambatan persebaran
                              <ol type="a">
                                <li>iklim</li>
                                <li>keadanaan tanah (edafik)</li>
                                <li>kondisi geografis</li>
                                <li>factor biotik</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          Pernahkah kalian terpikirkan mengapa fauna dan flora
                          di Indonesia ini sangat beragam jenisnya?
                          <p>
                            Keanekaragaman, distribusi, dan keterkaitan semua
                            satwa maupun tumbuhan di seluruh daerah di Indonesia
                            tidak jauh berbeda dari faktor utama, keanekaragaman
                            di Indonesia salah satunya bersumber dari sejarah
                            geologi akibat terpisahnya ketiga lempeng kerak
                            utama dan masa perubahan permukaan laut, serta iklim
                            yang mengubah daerah tersebut sepanjang waktu.
                            Pergerakan lempeng mengakibatkan tidak adanya
                            hubungan darat antara pulau-pulau di Indonesia, yang
                            mengakibatkan perbedaan besar antara fauna yang
                            menghuni fauna-fauna tersebut. Tidak adanya jembatan
                            darat merupakan halangan terbesar dalam pertukaran
                            fauna. Contohnya pada Zaman Pleistosen, secara
                            bergantian mengisolasi kehidupan pada pulau-pulau
                            selama ribuan tahun, dimana selama waktu permukaan
                            laut naik, dan menghubungkan dengan darat, fauna di
                            setiap pulau baru akan terisolasi dari populasi
                            induknya dan berkembang sendiri sesuai dengan
                            lingkungannya.
                          </p>
                          <p>
                            Selain faktor tersebut, hewan juga memiliki peranan
                            terhadap penyebaran tumbuhan flora. Bagaimana Bisa?
                            Serangga dapat membantu dalam proses penyerbukan
                            tanaman, burung dan tupai membantu dalam penyebaran
                            biji tumbuhan. kehidupan tumbuh-tumbuhan ini juga
                            mempengaruhi kehidupan faunanya.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
              <div className="card my-10" id="pengenalan">
                <div className="card-header">
                  <h3 className="card-title">
                    Mari, mengenal persebaran flora dan fauna di Indonesia!
                  </h3>
                </div>
                <div className="card-body py-2">
                  <section className="fs-6 fw-semibold text-gray-600">
                    <ul>
                      <li>
                        <Link to="/fauna">Fauna</Link>
                      </li>
                      <li>
                        <Link to="/flora">Flora</Link>
                      </li>
                      <li>
                        <Link to="/hutan">Hutan</Link>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
              <div className="card my-10" id="penulis">
                <div className="card-header">
                  <h3 className="card-title">Tentang Penulis</h3>
                </div>
                <div className="card-body py-2">
                  <section className="fs-6 fw-semibold text-gray-600 pb-6">
                    <div className="col-12 text-center">
                     <img src="/images/penulis.jpg" className="img-fluid" alt="img-penulis" />
                    </div>
                    <div className="col-12">
                    Sephia Nanda S. lahir di Kota Batu, Kabupaten Malang pada tanggal 19 September 2001. Jenjang pendidikan dasar ia tempuh di SDN Oro-Oro Ombo 02 (tahun 2007-2013) dan di SMPN 04 Batu (tahun 2013-2016). Adapun jenjang pendidikan menengah di SMAN 2 Kota Batu (tahun 2016-2019). Kemudian mengambil program studi Pendidikan geografi dan sedang menempuhnya saat ini di Universitas Negeri Malang.  
                    </div>
                    <div className="col-12">
                    Sephia, salah satu sapaan akrabnya saat ini, pengalaman selama kuliah di Universitas Negeri Malang merupakan hal hebat yang bisa dilalui hingga saat ini, banyak perjuangan dan pengalaman yang dapat diambil sampai detik ini.
                    </div>
                    <div className="col-12">
                    Begitulah catatan singkat tentang penulis buku ini. 

                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
