import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FloraContainer from "../../containers/FloraContainer";
export const Flora = (props)=>{
  let params=useParams();
  let slug=params.slug;
  let navigate=useNavigate();
  return (
      <FloraContainer slugFlora={slug} navigate={navigate}/>
  )
}

export default Flora;
