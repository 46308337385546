import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Flora from '../components/flora';
// middlewares
import AuthMiddleware from '../reducers/auth/middleware';
import FloraMiddleware from '../middleware/flora';

const mapStateToProps = ({ auth,flora }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  menu:flora.list,
  data:flora.dataFlora
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials),
    getList:()=>FloraMiddleware.getList(),
    getData:(data)=>FloraMiddleware.getData(data),
  }, dispatch);
};

class FloraContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func.isRequired,
    menu:PropTypes.array.isRequired,
    data:PropTypes.any
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    menu: [],
    data:null
  }
  constructor(props) {
    super(props);
    this.state = {
      slug:null
    };
  }

  UNSAFE_componentWillMount(){
    this.props.getList()
  }
  getData(slug){
    this.props.getData(slug)
  }
  componentWillUnmount(){
    this.setState({slug:null})
  }
  render() {
    const {slugFlora}=this.props
    if(this.state.slug !=slugFlora){
      this.state.slug=slugFlora
      this.getData(slugFlora)
    }
    return (
      <Flora
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloraContainer);
