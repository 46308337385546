import { combineReducers } from 'redux';
import AuthReducer from './auth';
import FaunaReducer from './fauna';
import FloraReducer from './flora';
import HutanReducer from './hutan';


export default combineReducers({
    // navigation: NavigationStateReducer,
    auth: AuthReducer,
    fauna: FaunaReducer,
    flora: FloraReducer,
    hutan: HutanReducer,
    
})