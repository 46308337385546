import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SideMenu extends Component {
  renderList() {
    const { menu,type } = this.props;
    return menu.map((menu, i) => (
      <div className="menu-item" key={"menu"+i}>
        <Link className="menu-link" to={"/"+type+"/" + menu.url}>
          <span className="menu-icon">
            <FontAwesomeIcon icon={faDotCircle} />
          </span>
          <span className="menu-title">{menu.name}</span>
        </Link>
      </div>
    ));
  }
  renderType(){
    const {type}=this.props
    return <div className="menu-item">
          <Link className="menu-link" to={"/"+type}>
            <div className="menu-content">
              <span className="menu-section fs-5 fw-bolder ps-1 py-1" style={{textTransform:"capitalize"}}>
                {type}
              </span>
            </div>
          </Link>
        </div>
  }
  render() {
    return (
      <div
        id="kt_app_sidebar_menu"
        data-kt-menu="true"
        className="menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6"
      >
        {this.renderType()}
        
        {this.renderList()}
      </div>
    );
  }
}
