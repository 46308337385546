import FaunaActions from "./actions";

const INITIAL_STATE = {
  data: null,
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  list: [],
  dataFauna: null,
  errorInternal: false,
};

function FaunaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FaunaActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case FaunaActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case FaunaActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        dataFauna: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case FaunaActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case FaunaActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case FaunaActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case FaunaActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        list: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case FaunaActions.ERROR_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
        dataFauna:null
      };
    case FaunaActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case FaunaActions.PROCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: true,
        isError: false,
        isSuccess: false,
        dataFauna:null,
      };
    //  case FaunaActions.GET_HELPER_COA2:
    //    return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCoa2:action.payload};
    case FaunaActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default FaunaReducer;
