import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import Hutan from '../components/hutan';
// middlewares
import AuthMiddleware from '../reducers/auth/middleware';
import HutanMiddleware from '../middleware/hutan';

const mapStateToProps = ({ auth,hutan }) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: auth.isProcessing,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  menu:hutan.list,
  data:hutan.dataHutan
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    signin: credentials => AuthMiddleware.signin(credentials),
    getList:()=>HutanMiddleware.getList(),
    getData:(data)=>HutanMiddleware.getData(data),
  }, dispatch);
};

class HutanContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func.isRequired,
    menu:PropTypes.array.isRequired,
    data:PropTypes.any
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    menu: [],
    data:null
  }
  constructor(props) {
    super(props);
    this.state = {
      slug:null
    };
  }

  UNSAFE_componentWillMount(){
    this.props.getList()
  }
  getData(slug){
    this.props.getData(slug)
  }
  componentWillUnmount(){
    this.setState({slug:null})
  }
  render() {
    const {slugHutan}=this.props
    if(this.state.slug !=slugHutan){
      this.state.slug=slugHutan
      this.getData(slugHutan)
    }
    return (
      <Hutan
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HutanContainer);
