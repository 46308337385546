const EN = {
  "Select Supplier": "Select Supplier",
  "Select Unit": "Select Unit",
  "Select Item": "Select Item",
  "Select Branch": "Select Branch",
  "Select Store": "Select Store",
  "Item": "Item",
  "Select Warehouse": "Select Warehouse",
  "Qty": "Qty",
  "Unit": "Unit",
  "Price": "Price",
  "Supplier": "Supplier",
  "Description": "Note",
  "Effecticve Date": "Effecticve Date",
  "Add Item": "Add Item",
  "Cash/Transfer": "Cash/Transfer",
  "Update Price": "Update Price",
  "Close": "Close",
  "Edit Item": "Edit Item",
  "Add Offering": "Add Offering",
  "Cash/Bank account": "Cash/Bank account",
  "Total Bill": "Total Bill",
  "Total Remaining": "Total Remaining",
  "Total Payment": "Total Payment",
  "Remaining Payment": "Remaining Payment",
  "Refund": "Refund",
  "No Invoice": "No Invoice",
  "Payment": "Payment",
  "Sub Total": "Sub Total",
  "Supplier No": "Supplier No",
  "Remaining": "Remaining",
  "No": "No",
  "Payment Date": "Payment Date",
  "Payment No": "Payment No",
  "Select PO": "Select PO",
  "Select Receiving": "Select Receiving",
  "Select Purchase Order": "Select Purchase Order",
  "Purchase Request No.": "Request No.",
  "Convertion": "Convertion",
  "Qty Receive": "Qty Receive",
  "Total Nett": "Total Nett",
  "Total Price": "Total Price",
  "Invoice No": "Invoice No",
  "Invoice Date": "Invoice Date",
  "Transaction Method": "Transaction Method",
  "Payment Type": "Payment Type",
  "BG Date": "BG Date",
  "No Supplier Invoice": "No Supplier Invoice",
  "Date Invoice Supplier": "Date Invoice Supplier",
  "Due Date": "Due Date",
  "Tax": "Tax",
  "Discount Price": "Discount (Rp)",
  "Date": "Date",
  "Delivery Date": "Delivery Date",
  "Branch": "Branch",
  "Store": "Store",
  "Action": "Action",
  "Add Details": "Add Details",
  "Home": "Home",
  "Transaction": "Transaction",
  "Purchase Request": "Purchase Request",
  "No. Request": "Request No.",
  "Status": "Status",
  "Created At": "Created At",
  "Updated At": "Updated At",
  "Add Invoice": "Add Invoice",
  "Edit Invoice": "Edit Invoice",
  "Edit": "Edit",
  "Delete": "Delete",
  "Print": "Print",
  "Select ID": "Select ID",
  "Credit Note (CN)": "Credit Note (CN)",
  Cash: "Cash",
  "Stock Available": "Stock Available",
  "Add Purchase Request": "Add Purchase Request",
  "Edit Purchase Request": "Edit Purchase Request",
  "You will delete this Item": "You will delete this Item",
  Cancel: "Cancel",
  "Deleting Data": "Deleting Data",
  "Please Wait ....": "Please Wait ....",
  "Are you sure?": "Are you sure?",
  "Customer Type": "Customer Type",
  Language: "Language",
  "Select Status": "Select Status",
  Offering: "Goods Offer",
  Receiving: "Goods Receipt",
  Mutation: "Goods Mutation",
  "Waiting For Approval": "Waiting for Approval",
  Procurement: "Procurement",
  "PR Rejected": "Rejected",
  "PO Rejected": "PO Rejected",
  "Waiting Center Approval": "Waiting HO Approval",
  "Waiting Central Approval": "Waiting HO Approval",
  "Waiting Receiving Item": "Waiting for GR",
  "Waiting For Payment": "Waiting for Payment",
  Done: "Done",
  "Warehouse Trasnfer": "Warehouse Transfer",
  "Qty Receive must be more than 0": "Qty Receive must be more than 0",
  "Out Of Qty Order": "Out Of Qty Order",
  "PO No": "Order No.",
  "Date PO": "Order Date",
  Approve: "Approve",
  "Warehouse Mutation": "Warehouse Mutation",
  "You need to write something!": "You need to write something!",
  "Quantity Mutation can't Exceed Quantity in Stock":"Quantity Mutation can't Exceed Quantity in Stock",
  "Quantity Mutation can't Exceed Quantity Request":"Quantity Mutation can't Exceed Quantity Request",
  "Quantity Order can't Exceed Quantity Request":"Quantity Order can't Exceed Quantity Request",
  "Enter your message": "Enter your message",
  "Select Transfer": "Select Transfer",
  "Approve This PO": "Approve This PO",
  "Purchase Recap": "Purchase Recap",
  "Add Supplier": "Add Supplier",
  Convert: "Convert",
  "Please Add Supplier And Quantity": "Please Add Supplier And Quantity",
  Detail: "Detail",
  "User will have Admin Privileges": "User will have Admin Privileges",
  "You will Convert this PR": "You will Convert this PR",
  "Reject This PO": "Reject This PO",
  "Add Purchase Order": "Add Purchase Order",
  "Edit Purchase Order": "Edit Purchase Order",
  Reject: "Reject",
  "Qty Mutation": "Qty Mutation",
  "Qty Order": "Qty Order",
  "Qty Request": "Qty Request",
  Save: "Save",
  "Date Delivery": "Delivery Date",
  "Discount Percent": "Discount (%)",
  Discount: "Discount (Rp)",
  "Price Nett": "Nett Price",
  "Nett Price": "Nett Price",
  "Price Total": "Total Price",
  "Qty Return": "Qty Return",
  "Total Return": "Total Return",
  "Canceling Data": "Canceling Data",
  "Branch Name": "Branch Name",
  "Add Purchase Return": "Add Purchase Return",
  "Purchase Return": "Purchase Return",
  "No. Purchase Return": "No. Purchase Return",
  Accept: "Accept",
  "Edit Purchase Return": "Edit Purchase Return",
  "You will cancel this Item": "You will cancel this Item",
  "Total Price Return": "Total Price Return",
  "Quantity Return can't Exceed Avaliable Stock":"Quantity Return can't Exceed Avaliable Stock",
  "No. Transaction": "No. Transaction",
  "ID Transaction": "ID Transaction",
  "Return Method": "Return Method",
  "Account Bank": "Account Bank",
  Total: "Total Price",
  "Expired Date": "Expired Date",
  "Add Convertion": "Add Convertion",
  "Code Item": "Item Code",
  "Discount Invoice": "Discount Invoice",
  "Discount Total": "Discount Total",
  "Tax Total": "Tax Total",
  "Grand Total": "Grand Total",
  "No PO": "Order No.",
  "Sub Warehouse": "Sub Warehouse",
  Warehouse: "Warehouse",
  "Receiving No": "Receiving No",
  "Qty Available": "Qty Available",
  "No. Po": "No. Po",
  "No. Invoice": "No. Invoice",
  "Date Invoice": "Date Invoice",
  "Supplier Name": "Supplier Name",
  "Band No": "Banderol",
  Reamining: "Remaining Qty",
  "Remaining Qty":"Remaining Qty",
  "Qty Received": "Qty Received",
  "Receiving (PB)": "Goods Receipt",
  "No. PB": "Receiving No.",
  "Upload Files": "Upload Files",
  "Edit Receiving": "Edit Receiving",
  "Add Receiving": "Add Receiving",
  "No. Receiving":"Receiving No.",
  "Document No.":"Document No.",
  "Delivery Date":"Delivery Date",
  max: "max",
  items: "items",
  "Shipping Date": "Shipping Date",
  Code: "Item Code",
  "No. Band": "Banderol",
  "Batch No": "Batch No",
  Note: "Note",
  "Document No": "Document No",
  "No.PR": "Request No.",
  "NO.PO": "Order No.",
  "Date Order": "Order Date",
  "Config Name": "Config Name",
  "Date Period":"Date Period",
  "No. Vehicle":"Plate No.",
  "No. Document":"Document No.",
  
};
export default EN;
