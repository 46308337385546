import HutanActions from "./actions";

const INITIAL_STATE = {
  data: null,
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  list: [],
  dataHutan: null,
  errorInternal: false,
};

function HutanReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HutanActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case HutanActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        errorMessage: [],
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case HutanActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        dataHutan: action.payload,
        isNewRecord: false,
        showModal: true,
      };
    case HutanActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case HutanActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case HutanActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case HutanActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        list: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case HutanActions.ERROR_DATA:
      return {
        ...state,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
        dataHutan:null
      };
    case HutanActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case HutanActions.PROCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: true,
        isError: false,
        isSuccess: false,
        dataHutan:null,
      };
    //  case HutanActions.GET_HELPER_COA2:
    //    return { ...state,errorInternal:false, isError: false,isSuccess:false,onSubmit:false,helperCoa2:action.payload};
    case HutanActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };

    default:
      return state;
  }
}

export default HutanReducer;
