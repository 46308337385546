export default class FloraActions {
  static NEW_DATA = "NEW_DATA";
  static UPDATE_DATA = "UPDATE_DATA";
  static SHOW_DATA = "SHOW_DATA";
  static VIEW_DATA = "VIEW_DATA";
  static DELETE_DATA = "DELETE_DATA";
  static ON_SUBMIT = "ON_SUBMIT";
  static ERROR_DATA = "ERROR_DATA";
  static PROCESS_DATA = "PROCESS_DATA";
  static RESET_DATA = "RESET_DATA";
  static SUCCESS_DATA = "SUCCESS_DATA";
  static ERROR_SERVER = "ERROR_SERVER";
  //static GET_HELPER_AccSetup ='GET_HELPER_AccSetup';
  static submit() {
    return {
      type: FloraActions.ON_SUBMIT,
    };
  }

  static create(data) {
    return {
      type: FloraActions.NEW_DATA,
      payload: data,
    };
  }

  static update(data) {
    return {
      type: FloraActions.UPDATE_DATA,
      payload: data,
    };
  }

  static view(data) {
    return {
      type: FloraActions.VIEW_DATA,
      payload: data,
    };
  }

  static delete(data) {
    return {
      type: FloraActions.DELETE_DATA,
    };
  }

  static list(data) {
    return {
      type: FloraActions.SHOW_DATA,
      payload: data,
    };
  }
  static error(error) {
    return {
      type: FloraActions.ERROR_DATA,
      payload: error,
    };
  }
  static success() {
    return {
      type: FloraActions.SUCCESS_DATA,
    };
  }
  static process() {
    return {
      type: FloraActions.PROCESS_DATA,
    };
  }
  static reset() {
    return {
      type: FloraActions.RESET_DATA,
    };
  }
  //   static getHelper(data){
  //     return{
  //       type : FloraActions.GET_HELPER_AccSetup,
  //       payload : data
  //     };

  //   }
  static errorInternal(error) {
    return {
      type: FloraActions.ERROR_SERVER,
      payload: error,
    };
  }
}
