import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Menu from './Nav'
import ListLang from './ListLang'
import cookieManager from '../../utils/cookieManager';
import i18n from '../../i18n';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClover, faDog, faHome, faHomeLg, faPlantWilt, faSunPlantWilt, faTree } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {
	constructor(props){
		super(props)
		this.state={
			user:null,
			currentUrl:location.pathname,
		}
	}
	render() {
	const {authUser,logout}=this.props
	return (
		<div id="kt_app_header" className="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: false, lg: true}" data-kt-sticky-name="app-header-sticky" data-kt-sticky-offset="{default: false, lg: '300px'}">
			<div className="app-container container-xxl d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
				<div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
					<div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
						<span className="svg-icon svg-icon-2">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
								<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
							</svg>
						</span>
					</div>
				</div>
				<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-1 me-lg-13">
					<a href="../../demo43/dist/index.html">
						<img alt="Logo" src="/images/Logo.png" className="h-sm-40px h-md-40px h-lg-100px theme-light-show" /> 
					</a>
					<span style={{marginLeft:"20px"}}>
						<h3>Ensiklopedia Digital</h3>
					<small>Sebaran Flora Fauna Indonesia Bedasarkan Karakteristik Ekosistem</small></span>
				</div>
				<div className="d-flex align-items-stretch justify-content-end flex-lg-grow-1" id="kt_app_header_wrapper">
					
					<div className="d-flex align-items-stretch" id="kt_app_header_menu_wrapper">
						
						<div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}">
							
							<div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-dark menu-arrow-gray-400 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0" id="#kt_app_header_menu" data-kt-menu="true">
								
								<div  data-kt-menu-placement="bottom-start" data-kt-menu-offset="22,0" className="menu-item  me-0 me-lg-2">
									
									<Link className="menu-link" to={'/'}>
										<span className="menu-icon">
											<FontAwesomeIcon icon={faHomeLg} size="lg"></FontAwesomeIcon>
										</span>
										<span className="menu-title">Home</span>
										<span className="menu-arrow d-lg-none"></span>
									</Link>
									
								</div>
								<div data-kt-menu-placement="bottom-start" data-kt-menu-offset="22,0" className="menu-item  me-0 me-lg-2">
									
									<Link className="menu-link" to={'/flora'}>
										<span className="menu-icon">
										<FontAwesomeIcon icon={faClover} size="lg"></FontAwesomeIcon>
										</span>
										<span className="menu-title">Flora</span>
										<span className="menu-arrow d-lg-none"></span>
									</Link>
								</div>
								<div data-kt-menu-placement="bottom-start" data-kt-menu-offset="22,0" className="menu-item  me-0 me-lg-2">
									
									<Link className="menu-link" to={'/fauna'}>
										<span className="menu-icon">
										<FontAwesomeIcon icon={faDog} size="lg"></FontAwesomeIcon>
										</span>
										<span className="menu-title">Fauna</span>
										<span className="menu-arrow d-lg-none"></span>
									</Link>
								</div>
								<div data-kt-menu-placement="bottom-start" data-kt-menu-offset="22,0" className="menu-item  me-0 me-lg-2">
									
									<Link className="menu-link" to={'/hutan'}>
										<span className="menu-icon">
										<FontAwesomeIcon icon={faTree} size="lg"></FontAwesomeIcon>
										</span>
										<span className="menu-title">Hutan</span>
										<span className="menu-arrow d-lg-none"></span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
  }	

export default Header;
