import React, { Component } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "../../containers/HomeContainer";
import { bindActionCreators } from "redux";
import Layout from "./Layout";
import Fauna from "../Fauna";
import Flora from "../Flora";
import Hutan from "../Hutan";

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkLogin: () => AuthMiddleware.checkLogin(),
    },
    dispatch
  );
};
class App extends Component {

  render() {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route
            path="fauna/:slug"
            element={<Fauna />}
          />
          <Route
            path="fauna"
            element={<Fauna />}
          />
           <Route
            path="hutan"
            element={<Hutan />}
          />
          <Route
            path="hutan/:slug"
            element={<Hutan />}
          />
          <Route
            path="flora"
            element={<Flora />}
          />
          <Route
            path="flora/:slug"
            element={<Flora />}
          />
        </Route>
      </Routes>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
