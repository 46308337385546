import cookieManager from './../../utils/cookieManager';
import axios from 'axios';
import AuthActions from './actions';

export default class AuthMiddleware {

  // Signin Functions Starts
  static signin(credentials) {
    return (dispatch) => {
      dispatch(AuthActions.signin());
      AuthMiddleware.signinWithUserCredentials(dispatch, credentials);
    };
  }

  static signinWithUserCredentials(dispatch, credentials) {
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      url: process.env.REST_URL +'login',
      responseType: 'json',
      data: {
        username: credentials.username,
        password: credentials.password,
        remember_me: credentials.remember_me,
      }
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        if (credentials) {
            var response=response.data;
            cookieManager.setUserToken(response.result.accessToken);
            cookieManager.setUserToken(response.result)
            dispatch(AuthActions.signinSuccessful(response.result.user));
        } else {
          dispatch(AuthActions.signinRejected('error no credentials'));
        }
      }
    })
    .catch((error) => {
      if(error.response !=undefined){
        const data=error.response.data
        dispatch(AuthActions.signinRejected(data.error));
      }else{
        dispatch(AuthActions.signinRejected(['Internal Server Error']));
      }
    });
  }
  // Signin Functions Ends

  // Logout Functions Starts
  static logout() {
    return (dispatch) => {
      dispatch(AuthActions.logout());
      AuthMiddleware.logoutFromAPI(dispatch);
    };
  }

  static logoutFromAPI(dispatch) {
    const token = cookieManager.getUserToken();
    cookieManager.removeUserToken();
    cookieManager.clearLocalStorage();
    cookieManager.removeUserToken();
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      url: process.env.REST_URL +'logout',
      data:[],
      responseType: 'json',
    })
    .then((response) => {
    })
    .catch((error) => {
     });
     dispatch(AuthActions.logoutSuccessful());
  }
  // Logout Functions Ends


  static checkLogin() {
    return (dispatch) => {
      AuthMiddleware.isLoggedIn(dispatch);
    };
  }

  // isLoggedIn
  static isLoggedIn(dispatch) {
      // const token = cookieManager.getUserToken();
      const token = cookieManager.getCookie('accessToken');
      AuthMiddleware.ensureAuthenticated(dispatch, token);
      if (token) {
        axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          url: process.env.REST_URL +'user',
          responseType: 'json',
        })
        .then((response) => {
          AuthMiddleware.ensureAuthenticated(dispatch, token,response.data.result);
        })
        .catch((error) => {
          dispatch(AuthActions.signinRejected(['token expired, please login again']));
         });
      } else {
        dispatch(AuthActions.isLoggedInFailure());
      }
  }

  // ensureAuthenticated
  static ensureAuthenticated(dispatch, token,data=null) {
    if (token) {
      dispatch(AuthActions.isLoggedInSuccess(data));
    } else {
      // never gonna happen
      // dispatch(AuthActions.signinRejected('no token'));
      dispatch(AuthActions.isLoggedInFailure());
    }
  }
}
