import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SideMenu from "../layouts/SideMenu";
import VideoPlayer from "../utilities/VideoJS";
import YoutubeEmbed from "../utilities/youtubeEmbed";
class Hutan extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    signin: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: "admin",
      password: "qwerty",
      showPassword: false,
    };
    this.changeData=this.changeData.bind(this)
  }


  renderOptions(){
    const { slug, menu ,navigate} = this.props;
    let options=[];
    const type="hutan"
    options.push(<option key={"rootHutan"} value={null}>Hutan</option>)
    menu.map((menu, i) => (
      options.push(<option key={i+"hutanOptions"} value={menu.name}>{menu.name}</option>)
    ));
    return options;
  }
  changeData(e){
   
    const {navigate} = this.props;
    const type="hutan"
    if(e.target.value =="Hutan"){
      navigate("/"+type)
    }else{
      navigate("/"+type+"/"+e.target.value)
    }
    
    // console.log(navigate);
  }
  
  render() {
    const { slug, menu, data } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Hutan</title>
        </Helmet>

        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content row">
            <div
              id="kt_app_sidebar"
              className="app-sidebar align-self-start col-3"
              data-kt-drawer="true"
              data-kt-drawer-name="app-sidebar"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '225px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
              <div
                className="card flex-grow-1 py-5"
                data-kt-sticky="true"
                data-kt-sticky-name="app-sidebar-menu-sticky"
                data-kt-sticky-offset="{default: false, xl: '500px'}"
                data-kt-sticky-width="225px"
                data-kt-sticky-left="auto"
                data-kt-sticky-top="125px"
                data-kt-sticky-animation="false"
                data-kt-sticky-zindex="95"
              >
                <div
                  className="hover-scroll-y px-1 px-lg-5"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_header, #kt_app_toolbar, #kt_app_footer"
                  data-kt-scroll-wrappers="#kt_app_main"
                  data-kt-scroll-offset="5px"
                >
                  <SideMenu menu={menu} slug={slug} type={"hutan"}></SideMenu>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <select className="w-100" onChange={(e)=>this.changeData(e)}>
                {this.renderOptions()}
              </select>
            </div>
            {data != null ? (
              <div className="card card-flush col-lg-9 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <div className="card-title">{data.title}</div>
                </div>

                <div className="card-body pt-0">
                  <div className="cover mb-2">
                    <img
                      src={data.cover}
                      className="img-fluid"
                      alt="Responsive image"
                    ></img>
                  </div>
                  <div
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></div>
                  {data.embedId != undefined ? (
                    <YoutubeEmbed embedId={data.embedId}></YoutubeEmbed>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="card card-flush col-lg-9 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                  <div className="card-title">
                    Kenali jenis hutan di sekitar kita dan pemanfaatannya!
                  </div>
                </div>

                <div className="card-body pt-0">
                  <div className="fs-5 fw-semibold text-gray-600">
                    <p>
                      Ternyata keberagaman hutan di Indonesia dipengaruhi oleh
                      kondisi iklim. Letak Indonesia yang berada di wilayah
                      iklim tropis menyebabkan sebagian besar wilayahnya
                      memiliki jenis hutan tropis. Lalu bagaimana dengan jenis
                      hutan di daerah Kabupaten Malang? Kabupaten Malang
                      memiliki jenis hutan hujan tropis, hutan musim dan hutan
                      bakau.
                    </p>
                    <ol type="1">
                      <li>
                        <h3>Hutan Tropis</h3>
                        <p>
                          Terdapat di daerah yang banyak mendapatkan hujan.
                          Pohon disini biasanya berdaun lebat, tanah dan udara
                          lembab disebabkan uap air sukar berevaporasi, pohon
                          hutan ini sering ditumbuhi oleh tumbuhan merambat
                          biasanya juga terdapat lumut dibagian pohon yang tidak
                          terkena sinar matahari. Hutan tropis ini terdapat di
                          daerah Trawas, daerah menuju Mojokerto dari arah Kota
                          Batu.
                        </p>
                      </li>
                      <li>
                        <h3>Hutan Musim</h3>
                        <p>
                          Selama musim kemarau pohon pohon di hutan musim akan
                          meranggas dan akan pulih kembali pada musim hujan.
                          Hutan musim sering disebut hutan homogen karena
                          terdiri dari satu jenis tumbuhan.
                        </p>
                      </li>
                      <li>
                        <h3>Hutan Mangrove</h3>
                        <p>
                          Terdapat di dataran rendah pantai yang berlumpur, akar
                          pohon bakau pada saat air surut dapat menahan abrasi
                          Di Kabupaten Malang hutan mangrove terdapat di
                          sepanjang Malang Selatan atau Pantai Malang Selatan.
                          Adapun konservasi hutan mangrove di pantai Clungup.
                        </p>
                        <p>
                          <ul>
                            <li>
                              Fungsi dan manfaat bakau secara fisik Penahan
                              abrasi pantai Penahan intrusi (meresapnya air laut
                              ke daratan) Penahan gelombang atau ombak
                              Menurunkan kadar karbondioksida dan polusi udara
                              Penambat bahan pencemar perairan pantai
                            </li>
                            <li>
                              Fungsi dan manfaat bakau secara biologi Habitat
                              bagi biota laut Sumber makanan bagi spesies hutan
                              mangrove Habitat satwa seperti kera, buaya, ular
                              dan burung
                            </li>
                            <li>
                              Fungsi dan manfaat bakau secara ekonomi Lokasi
                              pariwisata hutan mangrove Sumber makanan ikan,
                              udang, kepiting dan biota laut lain
                            </li>
                          </ul>
                        </p>
                      </li>
                    </ol>
                    <ul>
                      <li>
                        Kenali jenis hutan di sekitar kita dan pemanfaatannya!
                        <p>
                          Jenis hutan di Indonesia sangat beragam, mengapa bisa
                          beragam ya?
                        </p>
                        <p>
                          Nah ternyata keberagaman hutan di Indonesia
                          dipengaruhi oleh kondisi iklim. Letak Indonesia yang
                          berada di wilayah iklim tropis menyebabkan sebagian
                          besar wilayahnya memiliki jenis hutan tropis. Lalu
                          bagaimana dengan jenis hutan di daerah Kabupaten
                          Malang?
                        </p>
                        <p>
                          Kabupaten Malang memiliki jenis hutan musim dan hutan
                          bakau
                        </p>
                        <p>
                          <strong>Hutan Musim</strong>
                        </p>
                        <p>
                          Selama musim kemarau pohon pohon di hutan musim akan
                          meranggas dan akan pulih Kembali pada musim hujan.
                          Hutan musim sering disebut hutan homogen karena
                          terdiri dari satu jenis tumbuhan.
                        </p>
                        <p>Contoh hutan musim yang ada di daerah Kota Batu</p>
                        <p>
                          <strong>Hutan bakau</strong>
                        </p>
                        <p>
                          Terdapat di dataran rendah pantai yang belumpur, akar
                          pohon bakau pada saat air surut dapat menahan abrasi
                        </p>
                        <p>
                          Di Kabupaten Malang hutan bakau terdapat di Kondang
                          Buntung kawasan pesisir Sendangbiru
                        </p>
                      </li>
                    </ul>
                  </div>

                  {/* <VideoPlayer {...{
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [{
                        src: 'media/tes360.mp4',
                        type: 'video/mp4'
                      }]
                    }} /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Hutan;
