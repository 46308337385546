import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FaunaContainer from "../../containers/FaunaContainer";
export const Fauna = (props)=>{
  let params=useParams();
  let slug=params.slug;
  let navigate=useNavigate();
  return (
      <FaunaContainer slugFauna={slug} navigate={navigate}/>
  )
}

export default Fauna;
